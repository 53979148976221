const ChekmarkIcon = ({ color = '#3548FE' }) => (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.93699 7.26973C4.5448 7.67048 3.89981 7.67048 3.50761 7.26973L1.01813 4.72598C0.822855 4.52645 0.822855 4.20743 1.01813 4.0079C1.21944 3.8022 1.55051 3.80218 1.75184 4.00786L4.2223 6.53172L10.2483 0.374829C10.4497 0.169134 10.7807 0.169141 10.982 0.374843C11.1773 0.574373 11.1773 0.893384 10.982 1.09291L4.93699 7.26973Z"
        fill={color}
      />
    </svg>
);
  
export default ChekmarkIcon;