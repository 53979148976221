import * as RadixCheckbox from "@radix-ui/react-checkbox";
import { useController } from "react-hook-form";

import ChekmarkIcon from "./chekmarkIcon.jsx";

const Checkbox = ({ id, name, label, control, onClick }) => {
  const { field } = useController({ name, control });
  return (
    <div className="checkbox">
      <RadixCheckbox.Root
        id={id}
        {...field}
        value={undefined}
        checked={field.value}
        onCheckedChange={field.onChange}
        onClick={onClick}
      >
        <RadixCheckbox.Indicator className="indicator">
          <ChekmarkIcon color="white" />
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
      <label htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
