import {CLASSES, DOM} from '../helpers/_consts';

const initMenuToggle = () => {
  // NEW HEADER
  const $newBurger = $('.js-mob-burger');
  const $mainHeader = $('.js-main-header');
  const $headerSection = $('.js-header-section');
  const $headerSectionLabel = $('.js-header-section-label');
  const $sectionsWrapper = $('.js-sections-wrapper');
  const $headerContacts = $('.js-header-contact');

  const toggleMenu = () => {
    $newBurger.toggleClass(CLASSES.active);
    DOM.$body.toggleClass("scrollOff");
    $mainHeader.toggleClass(CLASSES.active);
    $headerSection.removeClass(CLASSES.openMob);
    $sectionsWrapper.removeClass(CLASSES.openMob);
  }

  function openHeaderSection () {
    if($(this).hasClass(CLASSES.openMob)) {
      return;
    }
    if (!$mainHeader.hasClass(CLASSES.active)) {
      toggleMenu();
    }
    $('.js-header-section.is-open').removeClass(CLASSES.openMob);
    $(this).addClass(CLASSES.openMob);
    $sectionsWrapper.addClass(CLASSES.openMob);
  }

  function closeSection (e) {
    if (!$(this).closest('.js-header-section').hasClass(CLASSES.openMob)) {
      return;
    }
    $(this).closest('.js-header-section.is-open').removeClass(CLASSES.openMob);
    if ($(this).hasClass('js-header-section-label-main')) {
      toggleMenu();
    }
    $sectionsWrapper.removeClass(CLASSES.openMob)
    e.stopPropagation();
  }

  function onContact (e) {
    e.stopPropagation();
    toggleMenu();
    $mainHeader.scrollTop(0);
  }

  function onHover (e) {
    e.stopPropagation();
    if (this.dataset.unhover) {
      clearTimeout(this.dataset.unhover);
    }
    const $section = $(this);
    const hoverTimeoutId = setTimeout(() => {
      $headerSection.removeClass(CLASSES.open);
      $section.addClass(CLASSES.open);
    }, 300);
    this.setAttribute('data-hover', hoverTimeoutId);
  }

  function onUnhover (e) {
    e.stopPropagation();
    if (this.dataset.hover) {
      clearTimeout(this.dataset.hover);
    }
    const $section = $(this);
    const unhoverTimoutId = setTimeout(() => {
      $section.removeClass(CLASSES.open);
    }, 300);
    this.setAttribute('data-unhover', unhoverTimoutId);
  }

  $newBurger.on('click', toggleMenu);
  $headerSection.on('click', openHeaderSection);
  $headerSectionLabel.on('click', closeSection);
  $headerContacts.on('click', onContact);
  $headerSection.on('mouseenter', onHover).on('mouseleave', onUnhover);

  $(window).on('scroll',() => {
    const HEADER_HEIGHT = 80;
    const scroll = $(window).scrollTop();

    if (scroll >= HEADER_HEIGHT) {
      $mainHeader.addClass("scroll-shadow");
    } else {
      $mainHeader.removeClass("scroll-shadow");
    }
  });
};

export default initMenuToggle;
