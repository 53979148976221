const Label = ({
  children,
  id,
  required = false,
  className = '',
}) => (
  <label htmlFor={id} className={className}>
    {`${children} ${required ? '*' : ''}`}
  </label>
);

export default Label;
