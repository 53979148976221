const UKRAINE_TIMEZONE = "Europe/Kyiv";

function convertHourToUkraineTimezone(hour) {
  const userLocalTime = new Date();
  userLocalTime.setHours(+hour);
  userLocalTime.setMinutes(0);

  const ukraineTime = new Intl.DateTimeFormat("en-US", {
    timeZone: UKRAINE_TIMEZONE,
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  }).format(userLocalTime);

  return ukraineTime;
}

// time format HH:00-HH:00
function convertTimeToUkraineTimezone(time) {
  const localHours = time.split("-").map((hour) => hour.split(":")[0]);

  return localHours.map(convertHourToUkraineTimezone).join("-");
}

function enchanceHeading(heading) {
  const path = window.location.pathname;

  if (path.includes("/course/")) {
    const courseName = document.getElementsByClassName(
      "js-contact-us-course-name"
    )[0];

    if (courseName && courseName.textContent) {
      return `${courseName.textContent.trim()}: ${heading}`;
    }
  }

  if (path.includes("/professium/")) {
    const proffesiumName = document.getElementsByClassName(
      "js-contact-us-proffesium-name"
    )[0];

    if (proffesiumName && proffesiumName.textContent) {
      return `${proffesiumName.textContent.trim()}: ${heading}`;
    }
  }

  return heading;
}

function buildPayload(form) {
  const { time, heading, ...rest } = form;
  const payload = { ...rest };

  payload.heading = enchanceHeading(heading);

  if (time) {
    payload.time = convertTimeToUkraineTimezone(time);
  }

  return payload;
}

export default function createLead(form) {
  const payload = buildPayload(form);

  return fetch(`${window.PIPEDRIVE_API_URL}/leads`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then(({ data }) => {
      if (!data.success) {
        throw new Error("Lead creation failed");
      }
    });
}
