import * as React from "react";
import { useForm } from "react-hook-form";

import TextInput from "./textInput.jsx";
import Textarea from "./textArea.jsx";
import Checkbox from "./checkbox.jsx";
import ComfortableTime from "./comfortableTime.jsx";
import Loading from "./loading.jsx";
import createLead from "./api";

const Form = ({ onSuccess, onFailure }) => {
  const [showComfortableTime, setShowComfortableTime] = React.useState(false);
  const toggleComfortableTime = () => setShowComfortableTime((show) => !show);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
      phone: "",
      heading: "",
      is_urgent: false,
      is_abroad: false,
    },
  });

  const onSubmit = (form) => {
    setIsSubmitting(true);
    createLead(form)
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        console.error(error);
        onFailure();
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        id="contact-us-name"
        name="name"
        required
        label="імʼя"
        register={register}
        error={errors.name}
      />
      <TextInput
        id="contact-us-phone"
        name="phone"
        required
        type="tel"
        label="телефон"
        placeholder="+38"
        register={register}
        error={errors.phone}
        validationPattern={
          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
        }
      />
      <Textarea
        id="contact-us-question"
        name="heading"
        label="запитання"
        placeholder="що вас цікавить?"
        register={register}
        autoheight
      />
      <Checkbox
        id="contact-us-asap"
        name="is_urgent"
        label="зателефонуйте якнайшвидше"
        control={control}
      />
      <Checkbox
        id="contact-us-outside-of-Ukraine"
        name="is_abroad"
        label="я не в Україні"
        onClick={toggleComfortableTime}
        control={control}
      />
      {showComfortableTime && <ComfortableTime name="time" control={control} />}

      <button type="submit" disabled={isSubmitting}>
        {isSubmitting ? <Loading /> : "передзвоніть мені"}
      </button>
    </form>
  );
};

export default Form;
