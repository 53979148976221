import Label from "./label.jsx";

const TextInput = ({
  id,
  name,
  required = false,
  label,
  placeholder,
  type = "text",
  register,
  error,
  validationPattern,
}) => {
  return (
    <>
      <Label id={id} required={required}>
        {label}
      </Label>
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        {...register(name, { required, pattern: validationPattern })}
        aria-invalid={!!error}
        className={`${error ? 'invalid' : ''}`}
      />
    </>
  );
};

export default TextInput;
