import React from "react";
import * as RadixSelect from "@radix-ui/react-select";
import { useController } from "react-hook-form";

import ChekmarkIcon from "./chekmarkIcon.jsx";

const Container = ({
  label,
  placeholder,
  children,
  withUnderlay = false,
  control,
  name,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { field } = useController({ name, control });
  const { ref: fieldRef, ...fieldRest } = field;

  return (
    <>
      {label}
      <RadixSelect.Root
        open={isOpen}
        onOpenChange={(open) => setIsOpen(open)}
        {...fieldRest}
        onValueChange={fieldRest.onChange}
      >
        <RadixSelect.Trigger className="select-trigger">
          <RadixSelect.Value placeholder={placeholder} />
          <RadixSelect.Icon>
            <ChevronDownIcon className="mr-1" />
          </RadixSelect.Icon>
        </RadixSelect.Trigger>

        <RadixSelect.Portal>
          <>
            {/* Fix for https://github.com/radix-ui/primitives/issues/1658 */}
            {withUnderlay && <Underlay open={isOpen} />}
            <RadixSelect.Content
              position="popper"
              side="bottom"
              sideOffset={6}
              id="select-contact-us-content"
              ref={fieldRef}
            >
              <RadixSelect.Viewport>{children}</RadixSelect.Viewport>
            </RadixSelect.Content>
          </>
        </RadixSelect.Portal>
      </RadixSelect.Root>
    </>
  );
};

// Fix based on https://github.com/radix-ui/primitives/issues/1658#issuecomment-1307242344
// We render a layer under the select in portal to "catch" clicks.
const Underlay = ({ open }) => {
  const [visible, setVisible] = React.useState(open);

  React.useEffect(() => {
    if (!open) {
      const timer = setTimeout(() => {
        setVisible(false);
      }, 1);
      return () => {
        clearTimeout(timer);
      };
    }
    setVisible(true);
    return () => {};
  }, [open]);

  return visible ? <div id="select-contact-us-underlay" /> : null;
};

const ChevronDownIcon = ({ className }) => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.56118 0.445386C9.5946 0.480765 9.62112 0.522792 9.63922 0.569063C9.65731 0.615333 9.66663 0.664936 9.66663 0.715032C9.66663 0.765127 9.65731 0.814731 9.63922 0.861001C9.62112 0.907271 9.5946 0.949299 9.56118 0.984677L5.25399 5.55494C5.22065 5.59041 5.18104 5.61855 5.13743 5.63775C5.09382 5.65695 5.04708 5.66683 4.99986 5.66683C4.95265 5.66683 4.9059 5.65695 4.8623 5.63775C4.81869 5.61855 4.77908 5.59041 4.74574 5.55494L0.438553 0.984677C0.371155 0.913162 0.333292 0.816168 0.333292 0.715031C0.333292 0.613895 0.371155 0.5169 0.438553 0.445386C0.505951 0.373872 0.597363 0.333695 0.692677 0.333695C0.787992 0.333695 0.879403 0.373872 0.946801 0.445386L4.99986 4.74676L9.05293 0.445386C9.08627 0.409919 9.12588 0.381779 9.16949 0.362579C9.21309 0.343379 9.25984 0.333496 9.30705 0.333496C9.35426 0.333496 9.40101 0.343379 9.44462 0.362579C9.48823 0.381779 9.52783 0.409919 9.56118 0.445386Z"
      fill="#101014"
      stroke="#101014"
      strokeWidth="0.2"
    />
  </svg>
);

const Item = ({ children, value }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <RadixSelect.Item
      key={value}
      className="select-item"
      value={value}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onTouchStart={() => setIsHovered(true)}
      onTouchEnd={() => setIsHovered(true)}
    >
      <RadixSelect.ItemIndicator>
        <ChekmarkIcon color={isHovered ? "white" : "#3548FE"} />
      </RadixSelect.ItemIndicator>
      <RadixSelect.ItemText>{children}</RadixSelect.ItemText>
    </RadixSelect.Item>
  );
};

const Select = {
  Container,
  Group: RadixSelect.Group,
  Label: RadixSelect.Label,
  Item,
};

export default Select;
