import React from "react";
import { createRoot } from "react-dom/client";

import ContactUs, { OPEN_EVENT } from "./contactUs.jsx";

function renderContactUs() {
  const container = document.querySelector("#contact-us-slot");
  createRoot(container).render(<ContactUs />);
}

function intitContactUs() {
    $(".js-prjctr-contact-us-trigger").on('click', (event) => {
        event.preventDefault();
        document.dispatchEvent(new Event(OPEN_EVENT));
    });

    renderContactUs();
}

export default intitContactUs;
