import * as React from "react";
import Label from "./label.jsx";

function calcHeight(value) {
  const numberOfLineBreaks = (value.match(/\n/g) || []).length;
  // min-height + lines x line-height + border
  const newHeight = 24 + numberOfLineBreaks * 24 + 1;

  return newHeight;
}

const useAutoHeight = (enabled, textAreaRef) => {
  React.useEffect(() => {
    const textArea = textAreaRef.current;
    const handler = () => {
      if (!enabled) {
        return;
      }

      if (textArea) {
        textArea.style.height = `${calcHeight(textArea.value)}px`;
      }
    };

    if (enabled && textArea) {
      textArea.addEventListener("keyup", handler);
    }

    return () => {
      if (enabled && textAreaRef.current) {
        textArea.removeEventListener("keyup", handler);
      }
    };
  }, [enabled]);
};

const Textarea = ({
  id,
  name,
  label,
  placeholder,
  register,
  autoheight = false,
}) => {
  const { onChange, onBlur, ref } = register(name);
  const textAreaRef = React.useRef(null);
  useAutoHeight(autoheight, textAreaRef);

  return (
    <>
      <Label id={id}>{label}</Label>
      <textarea
        rows={1}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        ref={(textArea) => {
          ref(textArea);
          textAreaRef.current = textArea;
        }}
        name={name}
      />
    </>
  );
};

export default Textarea;
