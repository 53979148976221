import * as React from "react";
import Form from "./form.jsx";

export const OPEN_EVENT = "PRJCTR_OPEN_CONTACT_US";

// Styles assets_src/sass/components/contact-us.sass
const ContactUs = () => {
  const [formState, setFormState] = React.useState("form");
  const [isContactUsOpened, setIsContactUsOpened] = React.useState(false);

  React.useEffect(() => {
    const openEventHandler = () => setIsContactUsOpened(true);

    document.addEventListener(OPEN_EVENT, openEventHandler);

    return () => document.removeEventListener(OPEN_EVENT, openEventHandler);
  }, []);

  const closeForm = () => {
    setIsContactUsOpened(false);
    setFormState("form");
  };
  const formRef = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current) {
        const { top, right } = formRef.current.getBoundingClientRect();

        if (event.x > right || event.y < top) {
          closeForm();
        }
      }
    };

    if (isContactUsOpened) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isContactUsOpened]);

  return (
    <div
      id="contact-us-form"
      className={isContactUsOpened ? "opened" : ""}
      ref={formRef}
    >
      <CloseButton onClick={closeForm} />
      {formState === "form" && (
        <>
          <p>сміливо заповнюйте заявку</p>
          <h5>
            залюбки
            <br />
            <span>допомагаємо</span>
            <br />
            <span>й не рекомендуємо</span>
            <br />
            зайвого
          </h5>
          <Form
            onSuccess={() => setFormState("success")}
            onFailure={() => setFormState("failure")}
          />
          <p className="text-sm/[18px]">
            Продовжуючи, ви погоджуєтеся з{" "}
            <a
              href={`https://prjctr.com/page/privacy-policy.html`}
              target="_blank"
              className="underline"
            >
              Політикою конфіденційності
            </a>
          </p>
        </>
      )}
      {formState === "success" && (
        <>
          <p>дякуємо!</p>
          <h5 className="success">
            очікуйте
            <br />
            <span>на наш</span>
            <br />
            <span>дзвінок!</span>
            <br />
          </h5>
          <img
            className="success"
            src="/assets/build/img/contact-us/success.webp"
            alt="очікуйте на наш дзвінок!"
          />
        </>
      )}
      {formState === "failure" && (
        <>
          <h5 className="failure">
            отакої
            <br />
            <span className="pl-16">щось пішло</span>
            <br />
            <span className="pl-16">не так!</span>
            <br />
          </h5>
          <div className="failure-container">
            <img
              src="/assets/build/img/contact-us/failure.webp"
              alt="отакої щось пішло не так"
            />
            <button
              type="button"
              className="try-again"
              onClick={() => setFormState("form")}
            >
              спробувати знову
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ContactUs;

const CloseButton = ({ onClick }) => (
  <button type="button" className="close" onClick={onClick}>
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.93994 6.99996L13.4666 1.47329C13.5758 1.34576 13.6329 1.18171 13.6264 1.01393C13.6199 0.846141 13.5504 0.686982 13.4317 0.568252C13.3129 0.449523 13.1538 0.379967 12.986 0.373487C12.8182 0.367006 12.6541 0.424077 12.5266 0.533294L6.99994 6.05996L1.47327 0.526628C1.34774 0.401092 1.17748 0.330566 0.999941 0.330566C0.822407 0.330566 0.652144 0.401092 0.526608 0.526628C0.401072 0.652163 0.330547 0.822426 0.330547 0.999961C0.330547 1.1775 0.401072 1.34776 0.526608 1.47329L6.05994 6.99996L0.526608 12.5266C0.45682 12.5864 0.40014 12.6599 0.360125 12.7426C0.320109 12.8254 0.297622 12.9154 0.294076 13.0073C0.29053 13.0991 0.306 13.1906 0.339516 13.2762C0.373033 13.3617 0.423871 13.4394 0.488841 13.5044C0.553811 13.5694 0.631509 13.6202 0.717059 13.6537C0.802609 13.6872 0.894164 13.7027 0.985977 13.6992C1.07779 13.6956 1.16788 13.6731 1.25059 13.6331C1.3333 13.5931 1.40684 13.5364 1.46661 13.4666L6.99994 7.93996L12.5266 13.4666C12.6541 13.5758 12.8182 13.6329 12.986 13.6264C13.1538 13.62 13.3129 13.5504 13.4317 13.4317C13.5504 13.3129 13.6199 13.1538 13.6264 12.986C13.6329 12.8182 13.5758 12.6542 13.4666 12.5266L7.93994 6.99996Z"
        fill="#3548FE"
      />
    </svg>
  </button>
);
