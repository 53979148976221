import Select from "./select.jsx";
import Label from "./label.jsx";

const ComfortableTime = ({ name, control }) => (
  <Select.Container
    id="contact-us-comfortable-time"
    name={name}
    label={
      <Label className="select-label" htmlFor="contact-us-comfortable-time">
        О котрій вам зручно розмовляти?
      </Label>
    }
    placeholder="будь-який час"
    withUnderlay
    control={control}
  >
    <Select.Group>
      <Select.Label className="select-group-label">
        {Intl.DateTimeFormat().resolvedOptions().timeZone}
      </Select.Label>
      <Select.Item value="10:00-19:00">10:00-19:00</Select.Item>
      <Select.Item value="10:00-12:00">10:00-12:00</Select.Item>
      <Select.Item value="12:00-14:00">12:00-14:00</Select.Item>
      <Select.Item value="14:00-16:00">14:00-16:00</Select.Item>
      <Select.Item value="16:00-19:00">16:00-19:00</Select.Item>
    </Select.Group>
  </Select.Container>
);

export default ComfortableTime;
